import { Button, illustrations } from '@popsure/dirty-swan';
import LinkTo from 'components/linkTo';
import { ENGLISH_LOCALE_IDENTIFIER } from 'constants/i18n';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { NextSeo } from 'next-seo';

import styles from './style.module.scss';

const PageNotTranslatedWrapper = () => {
  const { asPath } = useRouter();
  const { t } = useTranslation();
  return (
    <>
      <NextSeo
        title="Page not found"
        description="This page isn’t translated to German yet"
      />
      <div className="p16 pt72 pb96 mt72 bg-grey-200 d-flex fd-column ai-center jc-center">
        <div className="wmx3">
          <Image
            aria-hidden
            src={illustrations.supportSpeakingMessageChatTalk}
            height={96}
            width={96}
            alt="two overlapping speech bubbles"
          />
        </div>
        <h2 className="tc-grey-900 ta-center mt32 p-h2">
          {t('page.nottranslated.title')}
        </h2>
        <p className="p-p mt8 ta-center wmx5">
          {t('page.nottranslated.description')}
        </p>
        <div className={`mt24 d-flex w100 jc-center ${styles.buttonContainer}`}>
          <Button
            className="m8"
            onClick={() => {
              window.location.href = `/${ENGLISH_LOCALE_IDENTIFIER}/${asPath}`;
            }}
          >
            {t('page.nottranslated.action.changelocale')}
          </Button>
          <LinkTo
            href="/"
            className={`p-btn--secondary m8 wmn3 ${styles.link}`}
          >
            {t('page.nottranslated.action.gotohomepage')}
          </LinkTo>
        </div>
      </div>
    </>
  );
};

export default PageNotTranslatedWrapper;
