import { SliceZone } from '@prismicio/react';
import { PrismicDocument } from '@prismicio/types';
import { captureException } from '@sentry/nextjs';
import { AnnouncementBar } from 'components/AnnoucementBar';
import MissingRequiredFields from 'components/MissingRequiredFields';
import PageNotTranslatedWrapper from 'components/PageNotTranslatedWrapper';
import { featherAggregatedRating } from 'components/reviewBadge';
import { FORMATTED_DEFAULT_LOCALE, PRISMIC_LOCALES } from 'constants/i18n';
import { PRISMIC_EXCLUDE_FROM_SITEMAP_TAG } from 'constants/prismic';
import { DEFAULT_SUBDOMAIN_IDENTIFIER } from 'constants/subdomains';
import { ModalWrapper } from 'context';
import { GetStaticPathsContext, GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo, ProductJsonLd } from 'next-seo';
import ComponentManager from 'strapi/components/ComponentManager';
import {
  isStrapiPage,
  StrapiAnnouncementBarData,
  StrapiPageData,
} from 'strapi/types/utils';
import { fetchAnnouncementBar, fetchMany, fetchOne } from 'strapi/utils/api';
import { isPageMarkedForDeindex } from 'strapi/utils/seo';
import {
  generatePrismicPagePaths,
  generateStrapiPagePaths,
} from 'util/generateCmsPagePaths';
import { getNamespacesFromLocale } from 'util/getNamespacesFromLocale';
import { i18nLocaleFormatter } from 'util/i18nLocaleFormatter';
import { getUidFromPathSegments } from 'util/prismicPathResolver';

import { createPrismicClient } from '../../prismicio';
import { components } from '../../slices';
import styles from './style.module.scss';

const PrismicPage = ({
  page,
  navOffset,
  announcementBar,
}: {
  page: PrismicDocument;
  navOffset: number;
  announcementBar: StrapiAnnouncementBarData;
}) => {
  const {
    seo_title: seoTitle,
    seo_description: seoDescription,
    og_description: ogDescription,
    og_image: ogImage,
    product_name: productName,
    aggregate_offer_low_price: aggregateOfferLowPrice,
    slices,
  } = page.data;

  if (!seoTitle || !seoDescription) {
    return (
      <div className={`pt64 ${styles.container}`}>
        <MissingRequiredFields />;
      </div>
    );
  }

  const hasOgImage = Object.keys(ogImage).length !== 0;
  const isExcludedFromSiteMap = page.tags.includes(
    PRISMIC_EXCLUDE_FROM_SITEMAP_TAG
  );

  return (
    <>
      <NextSeo
        title={seoTitle}
        description={seoDescription}
        openGraph={{
          description: ogDescription,
          images: hasOgImage
            ? [
                {
                  url: ogImage.url,
                  width: ogImage.width,
                  height: ogImage.height,
                  alt: ogImage.alt,
                },
              ]
            : [],
        }}
        noindex={isExcludedFromSiteMap}
      />
      {productName && aggregateOfferLowPrice && (
        <ProductJsonLd
          productName={productName}
          description={ogDescription}
          aggregateRating={featherAggregatedRating}
          brand="Feather"
          aggregateOffer={{
            lowPrice: aggregateOfferLowPrice,
            priceCurrency: 'EUR',
          }}
          images={[ogImage.url]}
        />
      )}
      <div className={`pt64 ${styles.container}`}>
        {announcementBar && <AnnouncementBar {...announcementBar} />}
        <SliceZone
          slices={slices}
          components={components as never}
          context={{ navOffset }}
        />
      </div>
    </>
  );
};

const StrapiPage = ({
  page,
  announcementBar,
}: {
  page: StrapiPageData;
  announcementBar: StrapiAnnouncementBarData;
}) => {
  const {
    seo,
    tags,
    product_name: productName,
    aggregate_offer_low_price: aggregateOfferLowPrice,
  } = page;

  if (!seo) {
    throw new Error('Missing page metadata');
  }

  const { metaTitle, metaDescription, metaSocial, canonicalURL } = seo;
  const isMarkedForDeindex = isPageMarkedForDeindex(tags?.tags_list);

  if (!metaSocial || metaSocial.length === 0) {
    throw Error('Missing social media page preview data');
  }

  const [openGraphItem] = metaSocial;

  return (
    <>
      <NextSeo
        title={metaTitle}
        description={metaDescription}
        openGraph={{
          title: openGraphItem.title,
          description: openGraphItem.description,
          images: openGraphItem.image?.data
            ? [
                {
                  url: openGraphItem.image.data.attributes.url,
                  width: openGraphItem.image.data.attributes.width,
                  height: openGraphItem.image.data.attributes.height,
                  alt: openGraphItem.image.data.attributes.alternativeText,
                },
              ]
            : [],
        }}
        noindex={isMarkedForDeindex}
        canonical={canonicalURL}
      />
      {productName && aggregateOfferLowPrice && (
        <ProductJsonLd
          productName={productName}
          description={metaDescription}
          aggregateRating={featherAggregatedRating}
          brand="Feather"
          aggregateOffer={{
            lowPrice: aggregateOfferLowPrice,
            priceCurrency: 'EUR',
          }}
          images={
            openGraphItem.image?.data
              ? [openGraphItem.image?.data.attributes.url]
              : []
          }
        />
      )}
      <div className={`pt64 ${styles.container}`}>
        {announcementBar && <AnnouncementBar {...announcementBar} />}
        <ComponentManager blocks={page.items} />
      </div>
    </>
  );
};

const Page = ({
  page,
  navOffset,
  announcementBar,
}: {
  page: null | StrapiPageData | PrismicDocument;
  navOffset: number;
  announcementBar: StrapiAnnouncementBarData;
}) => {
  if (!page) return <PageNotTranslatedWrapper />; // See line 247
  return (
    <ModalWrapper>
      {isStrapiPage(page) ? (
        <StrapiPage page={page} announcementBar={announcementBar} />
      ) : (
        <PrismicPage
          page={page}
          navOffset={navOffset}
          announcementBar={announcementBar}
        />
      )}
    </ModalWrapper>
  );
};

export default Page;

export const getStaticProps: GetStaticProps = async ({
  params,
  locale,
  previewData,
  preview,
}) => {
  // params.uid should be an array segmented by '/': https://nextjs.org/docs/pages/building-your-application/routing/dynamic-routes#catch-all-segments
  if (!params?.uid || typeof params.uid === 'string') return { notFound: true };

  if (!locale) throw Error('Locale was not set during the build time');

  const parsedUid = getUidFromPathSegments(params.uid);

  const prismicClient = createPrismicClient({ previewData });

  let page = null;
  try {
    page =
      (await fetchOne('pages', {
        uid: parsedUid,
        locale,
        preview,
      })) ||
      (await prismicClient.getByUID('page', parsedUid, {
        lang: locale,
        fetchLinks: [
          'comparisontable.uid',
          'comparisontable.name',
          'comparisontable.collapsible_sections',
          'comparisontable.hide_details',
          'comparisontable.slices',
        ],
      }));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    // Only case when page not found is de-de placeholder for NotTranslatedWrapper (see line 302)
    // Otherwise we're always fetching pages that are from the list returned in getStaticPaths
    // Therefore we can safely ignore the error
  }

  const announcementBar = await fetchAnnouncementBar(
    parsedUid,
    locale,
    preview
  );

  const namespacesRequired = getNamespacesFromLocale(locale);

  const i18nProps = await serverSideTranslations(
    i18nLocaleFormatter(locale) || FORMATTED_DEFAULT_LOCALE,
    namespacesRequired
  );
  return {
    props: {
      page,
      announcementBar,
      ...i18nProps,
    },
  };
};

export const getStaticPaths = async ({ locales }: GetStaticPathsContext) => {
  if (!locales) throw Error('Locales undefined during the build time');

  try {
    const prismicPages = (
      await Promise.all(
        PRISMIC_LOCALES.map((locale) =>
          createPrismicClient().getAllByType('page', { lang: locale })
        ).flat()
      )
    ).flat();

    const strapiPages = (
      await Promise.all(
        locales.map((locale) => fetchMany('pages', { locale })).flat()
      )
    ).flat();

    // TODO: Add back paths for NotTranslatedWrapper [SIDE-1142]
    return {
      paths: [
        ...generatePrismicPagePaths(prismicPages, DEFAULT_SUBDOMAIN_IDENTIFIER),
        ...generateStrapiPagePaths(strapiPages, DEFAULT_SUBDOMAIN_IDENTIFIER),
      ],
      fallback: false,
    };
  } catch (error) {
    captureException(error);

    throw new Error('Failed to fetch CMS pages during static generation');
  }
};
